import { navigate } from "gatsby-link";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./FaqFeedback.module.css";

const FaqFeedback = () => {
    const [feedbackGiven, setFeedbackGiven] = useState(false);

    const onFeedbackYes = () => {
        setFeedbackGiven(true);
    };

    const onFeedbackNo = () => {
        navigate("/contact");
    };

    return (
        <div className={styles.FaqFeedback}>
            <span className={styles.FaqFeedbackTitle}>
                {feedbackGiven ? <FormattedMessage id="faqFeedbackGiven" /> : <FormattedMessage id="faqFeedbackTitle" />}
            </span>
            {!feedbackGiven && <React.Fragment>
                <span onClick={onFeedbackYes} className={styles.FaqFeedbackButton}><FormattedMessage id="faqFeedbackYes" /></span>
                <span onClick={onFeedbackNo} className={styles.FaqFeedbackButton}><FormattedMessage id="faqFeedbackNo" /></span>
            </React.Fragment>}
        </div>
    );
};

export default FaqFeedback;
export const faqData = [
    {titleLabel: "faqOneTitle", descriptionLabel: "faqOneDescription"},
    {titleLabel: "faqTwoTitle", descriptionLabel: "faqTwoDescription"},
    {titleLabel: "faqThreeTitle", descriptionLabel: "faqThreeDescription"},
    {titleLabel: "faqFourTitle", descriptionLabel: "faqFourDescription"},
    {titleLabel: "faqFiveTitle", descriptionLabel: "faqFiveDescription"},
    {titleLabel: "faqSixTitle", descriptionLabel: "faqSixDescription"},
    {titleLabel: "faqSevenTitle", descriptionLabel: "faqSevenDescription"},
    {titleLabel: "faqEightTitle", descriptionLabel: "faqEightDescription"},
    {titleLabel: "faqNineTitle", descriptionLabel: "faqNineDescription"},
    {titleLabel: "faqTenTitle", descriptionLabel: "faqTenDescription"},
    {titleLabel: "faqElevenTitle", descriptionLabel: "faqElevenDescription"},
    {titleLabel: "faqTwelveTitle", descriptionLabel: "faqTwelveDescription"},
    {titleLabel: "faqThirteenTitle", descriptionLabel: "faqThirteenDescription"},
    {titleLabel: "faqFourteenTitle", descriptionLabel: "faqFourteenDescription"},
    {titleLabel: "faqFifteenTitle", descriptionLabel: "faqFifteenDescription"},
    {titleLabel: "faqSixteenTitle", descriptionLabel: "faqSixteenDescription"},
    {titleLabel: "faqSeventeenTitle", descriptionLabel: "faqSeventeenDescription"}
];
import React, { useState } from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import FaqFeedback from "./FaqFeedback";
import styles from "./Faqs.module.css";

const Faqs = ({faqData, feedback = false}) => {
    const [openItems, setOpenItems] = useState(new Array(faqData.length).fill(false));

    const toggleItem = (key) => {
        setOpenItems(openItems.map((item, i) => key === i ? !item : item));
    };

    const renderFaqItem = (item, key) => {
        return (
            <div className={`${styles.FaqItem} ${openItems[key] ? styles.open : ""}`} key={key}>
                <h4 className={styles.FaqItemTitle} onClick={() => toggleItem(key)}><FormattedMessage id={item.titleLabel} /></h4>
                <p className={styles.FaqItemDescription}>
                    <FormattedHTMLMessage id={item.descriptionLabel} />
                    {feedback && <FaqFeedback />}
                </p>
            </div>
        );
    };

    return (
        <React.Fragment>
            {faqData.map(renderFaqItem)}
        </React.Fragment>
    );
}

export default Faqs;
import React from "react";
import Seo from "../components/info-site/Seo";
import Layout from "../layouts/en";
import FaqPage from "../components/info-site/FaqPage";

const Faq = props => {
    return (
        <Layout location={props.location}>
            <Seo titleId="faqTitle" descriptionId="faqDescription" />
            <FaqPage />
        </Layout>
    );
};

export default Faq;
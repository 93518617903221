import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { faqData } from "../../data/faqData";
import styles from "../../styles/info-site/FaqPage.module.css";
import { faAward, faUserShield, faUserPlus, faSmile } from "@fortawesome/free-solid-svg-icons";
import Faqs from "../styleguide/Faqs";
import GetStarted from "./GetStarted";

const FaqPage = () => {
    return (
        <React.Fragment>
            <div className={styles.Faq}>
                <h3><FormattedMessage id="faqTitle" /></h3>
                <Faqs faqData={faqData} />
                <div className={styles.FaqSocialProof}>
                    <div className={styles.FaqSocialProofItem}>
                        <FontAwesomeIcon icon={faAward} />
                        <h4><FormattedMessage id="faqSocialProofOneTitle" /></h4>
                        <p><FormattedMessage id="faqSocialProofOneDescription" /></p>
                    </div>
                    <div className={styles.FaqSocialProofItem}>
                        <FontAwesomeIcon icon={faUserShield} />
                        <h4><FormattedMessage id="faqSocialProofTwoTitle" /></h4>
                        <p><FormattedMessage id="faqSocialProofTwoDescription" /></p>
                    </div>
                    <div className={styles.FaqSocialProofItem}>
                        <FontAwesomeIcon icon={faUserPlus} />
                        <h4><FormattedMessage id="faqSocialProofThreeTitle" /></h4>
                        <p><FormattedMessage id="faqSocialProofThreeDescription" /></p>
                    </div>
                    <div className={styles.FaqSocialProofItem}>
                        <FontAwesomeIcon icon={faSmile} />
                        <h4><FormattedMessage id="faqSocialProofFourTitle" /></h4>
                        <p><FormattedMessage id="faqSocialProofFourDescription" /></p>
                    </div>
                </div>
            </div>
            <GetStarted />
        </React.Fragment>
    );
};

export default FaqPage;